import { sorts } from '@lifedot/constants/sort'
import { ParsedUrlQueryInput } from 'querystring'

type CityId = number | Array<string> | null

const isPathableCityId = (cityId?: CityId): cityId is number | [string] =>
  typeof cityId === 'number' || (Array.isArray(cityId) && cityId.length === 1)

type AreaListPageArg = {
  prefectureRoma: string
  cityId?: CityId
  page?: number
  order?: string
  fallback?: string
}

export type ShopListPageRouterArg = AreaListPageArg

const isPrefectureList = (arg: ShopListPageRouterArg) =>
  !('cityId' in arg && isPathableCityId(arg.cityId))

const isPrefectureCityList = (
  arg: ShopListPageRouterArg
): arg is AreaListPageArg => 'cityId' in arg && isPathableCityId(arg.cityId)

export const shopListPath = (arg: ShopListPageRouterArg): string | never => {
  if (isPrefectureList(arg)) return `/shop/pref-${arg.prefectureRoma}/`

  if (isPrefectureCityList(arg))
    return `/shop/pref-${arg.prefectureRoma}/city-${
      Array.isArray(arg.cityId) ? arg.cityId[0] : arg.cityId
    }/`

  throw new Error('非正規なURLパラメータです。')
}

export const shopListParams = (
  arg: ShopListPageRouterArg
): ParsedUrlQueryInput => {
  const params: ParsedUrlQueryInput = {}
  if ('cityId' in arg && !isPathableCityId(arg.cityId) && arg.cityId)
    params['f[city_ids][]'] = arg.cityId

  if (arg.page && arg.page > 1) params['page'] = arg.page

  if (arg.order && arg.order !== sorts.default?.field)
    params['order'] = arg.order

  if (arg.fallback) params['fallback'] = arg.fallback

  return params
}

export const shopListPathWithParamsForNextLink = (
  arg: ShopListPageRouterArg
): [string, ParsedUrlQueryInput] => {
  return [shopListPath(arg), shopListParams(arg)]
}
