import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import PhoneIcon from '@material-ui/icons/Phone'
import { lifedotCallCenterNumber } from '@lifedot/constants/phoneNumbers'

const styles = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    [mq('sp')]: {
      display: 'none'
    }
  }),
  title: css({
    background: palette.gray2,
    borderRadius: 4,
    padding: '4px 8px',
    fontWeight: 'bold'
  }),
  numBox: css({
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column'
  }),
  num: css({
    color: palette.accent02,
    display: 'inline-flex',
    alignItems: 'center'
  }),
  notes: css({
    color: palette.gray6,
    fontSize: 10
  })
}

export const HeaderTel: FC = () => (
  <div css={styles.root}>
    <p css={[typography.textXS, styles.title]}>
      霊園・墓地に
      <br />
      関するご相談
    </p>
    <p css={styles.numBox}>
      <b css={[typography.textL, styles.num]}>
        <PhoneIcon fontSize="small" />
        {lifedotCallCenterNumber}
      </b>
      <span css={styles.notes}>通話料無料 | 10:00〜19:00受付</span>
    </p>
  </div>
)
