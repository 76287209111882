export interface Sort {
  field: string
  order: 'asc' | 'desc'
}

interface SortWithName extends Sort {
  name: string
}

export const defaultSort = {
  field: 'default',
  order: 'desc' as const
}

export const sorts: Record<Sort['field'], Sort> = {
  default: defaultSort,
  ranking: {
    field: 'ranking',
    order: 'desc'
  },
  price: {
    field: 'price',
    order: 'asc'
  },
  geo: {
    field: 'geo',
    order: 'asc'
  }
} as const

export type SortFields = keyof typeof sorts

export const sortWithNames: Record<SortWithName['field'], SortWithName> = {
  default: {
    ...defaultSort,
    name: 'おすすめ順'
  },
  ranking: {
    field: 'ranking',
    order: 'desc',
    name: 'ランキング順'
  },
  price: {
    field: 'price',
    order: 'asc',
    name: '価格安い順'
  },
  geo: {
    field: 'geo',
    order: 'asc',
    name: '近い順'
  }
} as const
