export const prefectures = {
  hokkaido: '北海道',
  aomori: '青森県',
  iwate: '岩手県',
  miyagi: '宮城県',
  akita: '秋田県',
  yamagata: '山形県',
  fukushima: '福島県',
  ibaraki: '茨城県',
  tochigi: '栃木県',
  gunma: '群馬県',
  saitama: '埼玉県',
  chiba: '千葉県',
  tokyo: '東京都',
  kanagawa: '神奈川県',
  niigata: '新潟県',
  toyama: '富山県',
  ishikawa: '石川県',
  fukui: '福井県',
  yamanashi: '山梨県',
  nagano: '長野県',
  gifu: '岐阜県',
  shizuoka: '静岡県',
  aichi: '愛知県',
  mie: '三重県',
  shiga: '滋賀県',
  kyoto: '京都府',
  osaka: '大阪府',
  hyogo: '兵庫県',
  nara: '奈良県',
  wakayama: '和歌山県',
  tottori: '鳥取県',
  shimane: '島根県',
  okayama: '岡山県',
  hiroshima: '広島県',
  yamaguchi: '山口県',
  tokushima: '徳島県',
  kagawa: '香川県',
  ehime: '愛媛県',
  kochi: '高知県',
  fukuoka: '福岡県',
  saga: '佐賀県',
  nagasaki: '長崎県',
  kumamoto: '熊本県',
  oita: '大分県',
  miyazaki: '宮崎県',
  kagoshima: '鹿児島県',
  okinawa: '沖縄県'
} as const

export const noSuffixPrefectures = {
  hokkaido: '北海道',
  aomori: '青森',
  iwate: '岩手',
  miyagi: '宮城',
  akita: '秋田',
  yamagata: '山形',
  fukushima: '福島',
  ibaraki: '茨城',
  tochigi: '栃木',
  gunma: '群馬',
  saitama: '埼玉',
  chiba: '千葉',
  tokyo: '東京',
  kanagawa: '神奈川',
  niigata: '新潟',
  toyama: '富山',
  ishikawa: '石川',
  fukui: '福井',
  yamanashi: '山梨',
  nagano: '長野',
  gifu: '岐阜',
  shizuoka: '静岡',
  aichi: '愛知',
  mie: '三重',
  shiga: '滋賀',
  kyoto: '京都',
  osaka: '大阪',
  hyogo: '兵庫',
  nara: '奈良',
  wakayama: '和歌山',
  tottori: '鳥取',
  shimane: '島根',
  okayama: '岡山',
  hiroshima: '広島',
  yamaguchi: '山口',
  tokushima: '徳島',
  kagawa: '香川',
  ehime: '愛媛',
  kochi: '高知',
  fukuoka: '福岡',
  saga: '佐賀',
  nagasaki: '長崎',
  kumamoto: '熊本',
  oita: '大分',
  miyazaki: '宮崎',
  kagoshima: '鹿児島',
  okinawa: '沖縄'
} as const

export type PrefectureRomas = keyof typeof prefectures

export const invertPrefectures = Object.fromEntries(
  Object.entries(prefectures).map(([k, v]) => [v, k])
)

type RegionCode =
  | 'hokkaido'
  | 'tohoku'
  | 'kanto'
  | 'tokai'
  | 'hokuriku'
  | 'koushinetsu'
  | 'kansai'
  | 'chugoku'
  | 'shikoku'
  | 'kyushu'

export const prefectureRegions: Record<PrefectureRomas, RegionCode> = {
  hokkaido: 'hokkaido',
  aomori: 'tohoku',
  iwate: 'tohoku',
  miyagi: 'tohoku',
  akita: 'tohoku',
  yamagata: 'tohoku',
  fukushima: 'tohoku',
  ibaraki: 'kanto',
  tochigi: 'kanto',
  gunma: 'kanto',
  saitama: 'kanto',
  chiba: 'kanto',
  tokyo: 'kanto',
  kanagawa: 'kanto',
  niigata: 'koushinetsu',
  toyama: 'hokuriku',
  ishikawa: 'hokuriku',
  fukui: 'hokuriku',
  yamanashi: 'koushinetsu',
  nagano: 'koushinetsu',
  gifu: 'tokai',
  shizuoka: 'tokai',
  aichi: 'tokai',
  mie: 'tokai',
  shiga: 'kansai',
  kyoto: 'kansai',
  osaka: 'kansai',
  hyogo: 'kansai',
  nara: 'kansai',
  wakayama: 'kansai',
  tottori: 'chugoku',
  shimane: 'chugoku',
  okayama: 'chugoku',
  hiroshima: 'chugoku',
  yamaguchi: 'chugoku',
  tokushima: 'shikoku',
  kagawa: 'shikoku',
  ehime: 'shikoku',
  kochi: 'shikoku',
  fukuoka: 'kyushu',
  saga: 'kyushu',
  nagasaki: 'kyushu',
  kumamoto: 'kyushu',
  oita: 'kyushu',
  miyazaki: 'kyushu',
  kagoshima: 'kyushu',
  okinawa: 'kyushu'
}

export const regions: Record<RegionCode, string> = {
  hokkaido: '北海道',
  tohoku: '東北',
  kanto: '関東',
  tokai: '東海',
  hokuriku: '北陸',
  koushinetsu: '甲信越',
  kansai: '関西',
  chugoku: '中国',
  shikoku: '四国',
  kyushu: '九州'
}

export const prefecturesByRegion: Record<RegionCode, PrefectureRomas[]> = {
  hokkaido: ['hokkaido'],
  tohoku: ['aomori', 'akita', 'iwate', 'yamagata', 'miyagi', 'fukushima'],
  kanto: [
    'tokyo',
    'kanagawa',
    'saitama',
    'chiba',
    'ibaraki',
    'tochigi',
    'gunma'
  ],
  tokai: ['aichi', 'gifu', 'shizuoka', 'mie'],
  hokuriku: ['toyama', 'ishikawa', 'fukui'],
  koushinetsu: ['yamanashi', 'niigata', 'nagano'],
  kansai: ['osaka', 'hyogo', 'kyoto', 'shiga', 'nara', 'wakayama'],
  chugoku: ['okayama', 'hiroshima', 'tottori', 'shimane', 'yamaguchi'],
  shikoku: ['kagawa', 'ehime', 'kochi', 'tokushima'],
  kyushu: [
    'fukuoka',
    'oita',
    'miyazaki',
    'saga',
    'kumamoto',
    'nagasaki',
    'kagoshima',
    'okinawa'
  ]
}

export const digesticRegionGroup: Record<RegionCode, string> = {
  hokkaido: '北海道・東北',
  tohoku: '北海道・東北',
  kanto: '関東',
  tokai: '東海',
  hokuriku: '北陸・甲信越',
  koushinetsu: '北陸・甲信越',
  kansai: '関西',
  chugoku: '中国・四国',
  shikoku: '中国・四国',
  kyushu: '九州・沖縄'
}

export const urbanPrefectures = [
  'hokkaido',
  'tokyo',
  'kanagawa',
  'saitama',
  'chiba',
  'aichi',
  'fukuoka',
  'shiga',
  'kyoto',
  'osaka',
  'hyogo',
  'nara',
  'wakayama'
]

export const digesticPrefectures = Object.entries(prefectures).map<{
  roma: string
  name: string
  digesticRegion: string
}>(([roma, name]) => ({
  roma,
  name,
  digesticRegion:
    digesticRegionGroup[
      prefectureRegions[roma as PrefectureRomas] as RegionCode
    ]
}))
