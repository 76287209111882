import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'

const styles = {
  frame: css({
    width: '100%'
  }),
  content: css({
    display: 'flex'
  }),
  leftContent: css({
    flexShrink: 0
  }),
  rightContent: css({
    marginLeft: 8
  })
}

interface FrameProps {
  header?: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
  footer?: ReactNode
}

export const Frame: FC<FrameProps> = (props) => {
  const { header, leftContent, rightContent, footer } = props
  return (
    <div css={styles.frame}>
      <div>{header}</div>
      <div css={styles.content}>
        <div css={styles.leftContent}>{leftContent}</div>
        <div css={styles.rightContent}>{rightContent}</div>
      </div>
      <div>{footer}</div>
    </div>
  )
}
