import { FC } from 'react'
import { CardContent, CardContentProps } from '../CardContent'

type Props = Pick<CardContentProps, 'centering' | 'spacing' | 'children'> & {
  border?: CardContentProps['borderBottom']
}

export const CardHeader: FC<Props> = (props) => {
  const { border, ...rest } = props
  return <CardContent borderBottom={border} {...rest} />
}
