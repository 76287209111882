import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'

const styles = {
  main: css({
    width: '100%',
    minWidth: 0,
    [mq('min', WindowWidth.TABLET)]: {
      maxWidth: 740
    }
  })
}

type MainColumnProps = {
  children: ReactNode
}
export const MainColumn: FC<MainColumnProps> = ({ children }) => (
  <div css={styles.main}>{children}</div>
)
