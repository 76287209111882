import { AttributeCode } from '@lifedot/constants/searchChoices'
import { sorts } from '@lifedot/constants/sort'
import { ParsedUrlQueryInput } from 'querystring'

type SingleCondition = { code: string; attributeCode: string }
type Condition = SingleCondition | Array<SingleCondition>

const isPathableCondition = (
  condition?: Condition
): condition is SingleCondition | [SingleCondition] => {
  if (!condition) return false
  if (Array.isArray(condition) && condition.length !== 1) return false
  const target = Array.isArray(condition)
    ? condition[0] ?? { attributeCode: '' }
    : condition
  return (
    target.attributeCode !== AttributeCode.PRICE_MAX &&
    target.attributeCode !== AttributeCode.PRICE_MIN
  )
}

type CityId = number | Array<number> | null

const isPathableCityId = (cityId?: CityId): cityId is number | [number] =>
  typeof cityId === 'number' || (Array.isArray(cityId) && cityId.length === 1)

type StationId = number | Array<number> | null

const isPathableStationId = (
  stationId?: StationId
): stationId is number | [number] =>
  typeof stationId === 'number' ||
  (Array.isArray(stationId) && stationId.length === 1)

type AreaListPageArg = {
  prefectureRoma: string
  cityId?: CityId
  condition?: Condition
  page?: number
  order?: string
  fallback?: string
}

type StationListPageArg = {
  prefectureRoma: string
  stationId?: StationId
  condition?: Condition
  page?: number
  order?: string
  fallback?: string
}

export type ListPageRouterArg = AreaListPageArg | StationListPageArg

const isPrefectureList = (arg: ListPageRouterArg) =>
  !('cityId' in arg && isPathableCityId(arg.cityId)) &&
  !isPathableCondition(arg.condition) &&
  !('stationId' in arg && isPathableStationId(arg.stationId))

const isPrefectureCityList = (arg: ListPageRouterArg): arg is AreaListPageArg =>
  'cityId' in arg &&
  isPathableCityId(arg.cityId) &&
  !isPathableCondition(arg.condition)

const isPrefectureConditionList = (
  arg: ListPageRouterArg
): arg is AreaListPageArg & {
  condition: SingleCondition
} =>
  !('cityId' in arg && isPathableCityId(arg.cityId)) &&
  isPathableCondition(arg.condition) &&
  !('stationId' in arg && isPathableStationId(arg.stationId))

const isPrefectureCityConditionList = (
  arg: ListPageRouterArg
): arg is AreaListPageArg & {
  condition: SingleCondition
} =>
  'cityId' in arg &&
  isPathableCityId(arg.cityId) &&
  isPathableCondition(arg.condition)

const isPrefectureStationList = (
  arg: ListPageRouterArg
): arg is StationListPageArg =>
  'stationId' in arg &&
  isPathableStationId(arg.stationId) &&
  !isPathableCondition(arg.condition)

const isPrefectureStationConditionList = (
  arg: ListPageRouterArg
): arg is StationListPageArg & {
  condition: SingleCondition
} =>
  'stationId' in arg &&
  isPathableStationId(arg.stationId) &&
  isPathableCondition(arg.condition)

export const listPath = (arg: ListPageRouterArg): string | never => {
  if (isPrefectureList(arg)) return `/ohaka/pref-${arg.prefectureRoma}/list/`

  if (isPrefectureCityList(arg))
    return `/ohaka/pref-${arg.prefectureRoma}/city-${
      Array.isArray(arg.cityId) ? arg.cityId[0] : arg.cityId
    }/list/`

  if (isPrefectureConditionList(arg))
    return `/ohaka/pref-${arg.prefectureRoma}/cond-${
      Array.isArray(arg.condition)
        ? arg.condition[0]?.attributeCode
        : arg.condition.attributeCode
    }-${
      Array.isArray(arg.condition) ? arg.condition[0]?.code : arg.condition.code
    }/list/`

  if (isPrefectureCityConditionList(arg))
    return `/ohaka/pref-${arg.prefectureRoma}/city-${
      Array.isArray(arg.cityId) ? arg.cityId[0] : arg.cityId
    }/cond-${
      Array.isArray(arg.condition)
        ? arg.condition[0]?.attributeCode
        : arg.condition.attributeCode
    }-${
      Array.isArray(arg.condition) ? arg.condition[0]?.code : arg.condition.code
    }/list/`

  if (isPrefectureStationConditionList(arg))
    return `/ohaka/pref-${arg.prefectureRoma}/st-${
      Array.isArray(arg.stationId) ? arg.stationId[0] : arg.stationId
    }/cond-${
      Array.isArray(arg.condition)
        ? arg.condition[0]?.attributeCode
        : arg.condition.attributeCode
    }-${
      Array.isArray(arg.condition) ? arg.condition[0]?.code : arg.condition.code
    }/list/`

  if (isPrefectureStationList(arg))
    return `/ohaka/pref-${arg.prefectureRoma}/st-${
      Array.isArray(arg.stationId) ? arg.stationId[0] : arg.stationId
    }/list/`

  throw new Error('非正規なURLパラメータです。')
}

export const listParams = (arg: ListPageRouterArg): ParsedUrlQueryInput => {
  const params: ParsedUrlQueryInput = {}
  if ('cityId' in arg && !isPathableCityId(arg.cityId) && arg.cityId)
    params['f[city_ids][]'] = arg.cityId

  if (
    'stationId' in arg &&
    !isPathableStationId(arg.stationId) &&
    arg.stationId
  )
    params['f[station_ids][]'] = arg.stationId

  if (!isPathableCondition(arg.condition) && arg.condition)
    arg.condition.forEach((condition) => {
      if (
        condition.attributeCode === AttributeCode.PRICE_MAX ||
        condition.attributeCode === AttributeCode.PRICE_MIN
      ) {
        params[`f[${condition.attributeCode}]`] = condition.code
        return
      }
      const target = params[`f[${condition.attributeCode}][]`]
      if (Array.isArray(target)) target.push(condition.code)
      else params[`f[${condition.attributeCode}][]`] = [condition.code]
    })

  if (arg.page && arg.page > 1) params['page'] = arg.page

  if (arg.order && arg.order !== sorts.default?.field)
    params['order'] = arg.order

  if (arg.fallback) params['fallback'] = arg.fallback

  return params
}

export const listPathWithParamsForNextLink = (
  arg: ListPageRouterArg
): [string, ParsedUrlQueryInput] => {
  return [listPath(arg), listParams(arg)]
}
