import { FC } from 'react'
import { MainColumn, MultiColumns, SubColumn } from '@lifedot/layout'
import { Banners } from '@lifedot/components/Banners'
import { AreaLinkAccordions } from '@/components/AreaLinkAccordions/AreaLinkAccordions'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import ContentLoader from 'react-content-loader'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  mainColumn: css({
    backgroundColor: palette.white
  }),
  breadLoader: css({
    height: 22,
    width: '100%',
    [mq('sp')]: {
      height: 20
    }
  }),
  bread: css({
    x: 0,
    y: 4,
    rx: 5,
    ry: 5,
    width: 600,
    height: 18,
    [mq('sp')]: {
      x: 8,
      width: 320,
      height: 20
    }
  }),
  header: css({
    padding: '24px 40px',
    [mq('sp')]: {
      padding: 12
    }
  }),
  content: css({
    padding: '20px 40px',
    [mq('sp')]: {
      padding: 12
    }
  }),
  titleLoader: css({
    width: '100%',
    height: 112,
    [mq('sp')]: {
      height: 86
    }
  }),
  title: css({
    rx: 5,
    ry: 5,
    width: 660,
    height: 44,
    [mq('sp')]: {
      width: '100%',
      height: 34
    }
  }),
  subTitle: css({
    y: 52,
    rx: 5,
    ry: 5,
    width: 400,
    height: 44,
    [mq('sp')]: {
      y: 42,
      width: '80%',
      height: 34
    }
  }),
  imageLoader: css({
    height: 416,
    width: '100%',
    [mq('sp')]: {
      height: 218
    }
  }),
  image: css({
    width: 740,
    height: 416,
    [mq('sp')]: {
      width: '100%',
      height: 218
    }
  }),
  contentLoader: css({
    height: 160,
    width: '100%',
    [mq('sp')]: {
      height: 120
    }
  }),
  text: css({
    rx: 5,
    ry: 5,
    width: '100%',
    height: 10,
    [mq('sp')]: {
      height: 10
    }
  }),
  text2: css({
    y: 30,
    rx: 5,
    ry: 5,
    width: '100%',
    height: 10,
    [mq('sp')]: {
      height: 10
    }
  }),
  text3: css({
    y: 60,
    rx: 5,
    ry: 5,
    width: '80%',
    height: 10,
    [mq('sp')]: {
      height: 10
    }
  })
}

export const PostDummyTemplate: FC = () => {
  return (
    <>
      <ContentLoader css={styles.breadLoader} backgroundColor={palette.gray2}>
        <rect css={styles.bread} />
      </ContentLoader>
      <MultiColumns>
        <MainColumn>
          <div css={styles.mainColumn}>
            <div css={styles.header}>
              <ContentLoader
                css={styles.titleLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.title} />
                <rect css={styles.subTitle} />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader
                css={styles.imageLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.image} />
              </ContentLoader>
            </div>
            <div css={styles.content}>
              <ContentLoader
                css={styles.contentLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.text} />
                <rect css={styles.text2} />
                <rect css={styles.text3} />
              </ContentLoader>
              <ContentLoader
                css={styles.contentLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.text} />
                <rect css={styles.text2} />
                <rect css={styles.text3} />
              </ContentLoader>
              <ContentLoader
                css={styles.contentLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.text} />
                <rect css={styles.text2} />
                <rect css={styles.text3} />
              </ContentLoader>
              <ContentLoader
                css={styles.contentLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.text} />
                <rect css={styles.text2} />
                <rect css={styles.text3} />
              </ContentLoader>
              <ContentLoader
                css={styles.contentLoader}
                backgroundColor={palette.gray2}
              >
                <rect css={styles.text} />
                <rect css={styles.text2} />
                <rect css={styles.text3} />
              </ContentLoader>
            </div>
          </div>
        </MainColumn>
        <SubColumn>
          <AddMarginWrapper spacing={3}>
            <Banners />
            <AreaLinkAccordions />
          </AddMarginWrapper>
        </SubColumn>
      </MultiColumns>
    </>
  )
}
