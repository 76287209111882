import { FC } from 'react'
import { css } from '@emotion/react'
import Image from 'next/image'
import { Card, CardContent } from '@lifedot/atoms/Card'
import { typography } from '@lifedot/styles/typography'
import { replacementImageForPost } from '@lifedot/constants/images'
import { palette } from '@lifedot/styles/palette'
import ScheduleIcon from '@material-ui/icons/Schedule'
import dayjs from 'dayjs'

const styles = {
  card: css({
    display: 'flex',
    alignItems: 'center'
  }),
  img: (horizontal: PostCardProps['horizontal']) =>
    css({
      width: horizontal ? '20%' : '100%',
      position: 'relative',
      fontSize: 0,
      flexShrink: 0
    }),
  title: (horizontal: PostCardProps['horizontal']) =>
    css({
      fontWeight: 'bold',
      margin: horizontal ? '0 0 0 8px' : '8px 0 0'
    }),
  date: css({
    color: palette.gray6,
    display: 'flex',
    alignItems: 'center',
    marginTop: 4
  })
}

export interface PostCardProps {
  thumbnailUrl?: string | null
  thumbnailAlt?: string | null
  title: string
  lastUpdate?: string
  horizontal?: boolean
}

export const PostCard: FC<PostCardProps> = ({
  thumbnailUrl,
  thumbnailAlt,
  title,
  lastUpdate,
  horizontal
}) => {
  return (
    <Card shadow round={1}>
      <CardContent spacing={3}>
        <div css={horizontal && styles.card}>
          <figure css={styles.img(horizontal)}>
            <Image
              src={thumbnailUrl ?? replacementImageForPost.path}
              alt={
                thumbnailAlt ??
                (thumbnailUrl ? title : replacementImageForPost.alt)
              }
              width={430}
              height={242}
            />
          </figure>
          <div>
            <p
              css={[
                horizontal ? typography.textS : typography.textM,
                styles.title(horizontal)
              ]}
            >
              {title}
            </p>
            {lastUpdate && (
              <p css={[typography.textXS, styles.date]}>
                <ScheduleIcon style={{ fontSize: 14 }} />
                {dayjs(lastUpdate).format('YYYY.M.DD')}
              </p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
