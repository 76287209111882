import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  root: css({
    position: 'relative',
    display: 'inline-flex'
  }),
  badge: css({
    position: 'absolute',
    top: 0,
    right: 0,
    height: 20,
    minWidth: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.accent,
    color: palette.white,
    borderRadius: 12,
    border: `1px solid ${palette.white}`,
    fontSize: 12,
    transform: 'translate(50%, -50%)',
    boxSizing: 'content-box'
  })
}

export interface BadgeProps {
  content: ReactNode
  children?: ReactNode
}

export const Badge: FC<BadgeProps> = (props) => {
  const { content } = props
  return (
    <div css={styles.root}>
      {props.children}
      <span css={[styles.badge, typography.textXS]}>{content}</span>
    </div>
  )
}
