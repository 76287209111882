import { FC, ReactNode, memo } from 'react'
import { css } from '@emotion/react'
import { Footer } from '@lifedot/components/Footer'
import { palette } from '@lifedot/styles/palette'
import {
  HeaderWithTools,
  HeaderWithToolsProps
} from '@lifedot/components/HeaderWithTools'
import { StockListWithButton } from '@lifedot/components/StockList/StockListWithButton'

const styles = {
  root: css({
    background: palette.gray1
  }),
  body: css({
    maxWidth: 1080,
    margin: 'auto'
  })
}

type BaseLayoutProps = {
  children: ReactNode
}

export const BaseLayout: FC<BaseLayoutProps> = (props) => {
  const { children } = props
  return (
    <div css={styles.root}>
      <MemorizedHeader />
      <div css={styles.body}>{children}</div>
      <MemorizedFooter />
    </div>
  )
}

const MemorizedFooter = memo(() => <Footer />)
const MemorizedHeader = memo<HeaderWithToolsProps>(() => (
  <HeaderWithTools additionalTool={<StockListWithButton />} />
))
