import { FC, HTMLAttributes, ReactNode, useCallback, useState } from 'react'
import { css } from '@emotion/react'
import { transition } from '@lifedot/styles/transition'

const styles = {
  summary: css({
    cursor: 'pointer',
    '@media (hover: hover)': {
      transition: transition('easeInOut'),
      ':hover': {
        opacity: 0.7
      }
    }
  }),
  content: css({
    maxHeight: 0,
    overflow: 'hidden',
    transition: transition('easeInOut')
  }),
  open: css({
    maxHeight: 2000
  })
}

export interface AccordionProps
  extends Omit<HTMLAttributes<HTMLDetailsElement>, 'title'> {
  title: ReactNode
  isOpen?: boolean
  vanishing?: boolean
}

export const Accordion: FC<AccordionProps> = (props) => {
  const {
    title,
    isOpen: passedOpen,
    vanishing = false,
    children,
    ...rest
  } = props
  const [open, setOpen] = useState<boolean>(false)
  const handleToggle = useCallback(() => setOpen((prev) => !prev), [])
  const newOpen = passedOpen ?? open
  return (
    <details onToggle={handleToggle} open={newOpen} {...rest}>
      <summary css={styles.summary}>{title}</summary>
      <div css={[styles.content, newOpen && styles.open]}>
        {!vanishing || newOpen ? children : null}
      </div>
    </details>
  )
}
