import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette, MainAndAccent } from '@lifedot/styles/palette'
import { elevation } from '@lifedot/styles/elevation'

const styles = {
  root: (arg: CardProps) =>
    css({
      background: palette.white,
      borderWidth: arg.outlineWidth,
      borderStyle: 'solid',
      borderColor:
        arg.outlineColor === 'main'
          ? palette.main
          : arg.outlineColor === 'accent'
          ? palette.accent
          : palette.gray2,
      borderRadius: (arg.round ?? 0) * 4,
      boxShadow: `${arg.shadow ? elevation[1] : 'none'}`,
      overflow: 'hidden'
    })
}

export interface CardProps {
  outlineWidth?: 0 | 1 | 2
  outlineColor?: MainAndAccent
  shadow?: boolean
  round?: 0 | 1 | 2
  children?: ReactNode
}

export const Card: FC<CardProps> = (props) => {
  const {
    children,
    outlineWidth = 0,
    shadow = false,
    round = 0,
    outlineColor
  } = props
  return (
    <div css={styles.root({ outlineWidth, shadow, round, outlineColor })}>
      {children}
    </div>
  )
}
