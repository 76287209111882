import { ScriptHTMLAttributes, useMemo } from 'react'
import * as React from 'react'
import { markup } from '@lifedot/utils'
import { lifedotCallCenterNumber } from '@lifedot/constants/phoneNumbers'

interface WebPageJsonLdProps extends ScriptHTMLAttributes<HTMLScriptElement> {
  description?: string
  url?: string
  dateModified?: string
}

const makeJsonLd = (props?: WebPageJsonLdProps) =>
  JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: 'ライフドット',
    description: props?.description,
    url: props?.url,
    dateModified: props?.dateModified,
    mainEntityOfPage: {
      '@type': 'WebSite',
      '@id': 'https://www.lifedot.jp/',
      name: 'お墓探し'
    },
    reviewedBy: {
      '@type': 'Person',
      name: '吉川美津子',
      jobTitle: '終活・葬送ソーシャルワーカー',
      description:
        '大手葬祭業者、大手仏壇・墓石販売業者勤務を経て、葬送コンサルタントとして独立。起業コンサルティングや人材育成、取材・執筆などを行っている。近年は葬送関連事業と並行しながら社会福祉士として、また介護職として福祉・介護の現場でも活動。「生き方」「逝き方」両輪の橋渡しを模索中。',
      image:
        'https://production-ending-images.s3.amazonaws.com/uploads/ending/path/4895/thumbnail_kikkawasan.jpg',
      hasCredential: '社会福祉士／FP（ファイナンシャル・プランナー）',
      sameAs: [
        'http://www.1sogi.com/info/profile.html',
        'https://funeral-manner.org/kikukawa/',
        'https://www.facebook.com/mitsuko.kikkawa',
        'https://news.yahoo.co.jp/byline/kikkawamitsuko/',
        'https://allabout.co.jp/gm/gp/33/'
      ],
      worksFor: {
        '@type': 'Organization',
        name: 'アルック／葬儀ビジネス研究所'
      }
    },
    publisher: {
      '@type': 'Organization',
      name: 'ライフドット',
      logo: {
        '@type': 'ImageObject',
        url: 'https://s3.lifedot.jp/uploads/ending/path/52461/logo.png'
      },
      telephone: lifedotCallCenterNumber
    },
    author: {
      '@type': 'Person',
      name: 'ライフドット編集部',
      description:
        'ライフエンディングにまつわる不安や悩みの解消を目指し、情報の発信しています。内容が難しくなりがちなエンディング業界の情報を、「読者に寄り添ってわかりやすく発信する」を心がけて提供しております。'
    }
  })

export const WebPageJsonLd: React.FC<WebPageJsonLdProps> = ({
  description,
  url,
  dateModified,
  ...props
}) => {
  const json = useMemo(
    () => makeJsonLd({ description, url, dateModified }),
    [dateModified, description, url]
  )

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={markup(json)}
      {...props}
    />
  )
}
