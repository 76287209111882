import { FC } from 'react'
import { CardContent, CardContentProps } from '../CardContent'

type Props = Pick<CardContentProps, 'centering' | 'spacing' | 'children'> & {
  border?: CardContentProps['borderTop']
}

export const CardFooter: FC<Props> = (props) => {
  const { border, ...rest } = props
  return <CardContent borderTop={border} {...rest} />
}
