import { FC } from 'react'
import { css } from '@emotion/react'
import Image from 'next/image'
import { EventTracker } from '@lifedot/tracking'
import { lifedotCallCenterNumber } from '@lifedot/constants/phoneNumbers'
import { router } from '@lifedot/router'

const styles = {
  horizontal: css({
    display: 'flex'
  }),
  anchor: (orientation: BannersProps['orientation']) =>
    css({
      display: 'block',
      maxWidth: 368,
      // next-imageのinline-blockで不要な余白ができるため
      fontSize: 0,
      ':not(:first-of-type)': {
        margin: orientation === 'horizontal' ? '0 0 0 20px' : '12px 0 0 0'
      }
    })
}

const banners = ['call', 'areaRequest', 'hakajimai', 'client'] as const

interface BannersProps {
  shows?: Array<(typeof banners)[number]>
  orientation?: 'horizontal' | 'vertical'
}

export const Banners: FC<BannersProps> = ({
  shows = banners,
  orientation = 'vertical'
}) => {
  return (
    <div css={[orientation === 'horizontal' && styles.horizontal]}>
      {shows.includes('call') && (
        <EventTracker label="side_相談窓口" action="click" clone>
          <a
            href={router.call()}
            target="_blank"
            css={styles.anchor(orientation)}
            rel="noopener noreferrer nofollow"
          >
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/79153/bn_call_sidebar.png"
              alt={`電話相談：${lifedotCallCenterNumber}`}
              width={600}
              height={336}
            />
          </a>
        </EventTracker>
      )}
      {shows.includes('areaRequest') && (
        <EventTracker label="side_霊園資料お届け" action="click" clone>
          <a
            href="/ohaka/area_partner_requests/new/"
            target="_blank"
            css={styles.anchor(orientation)}
          >
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/52233/bn_proposal_sidebar.png"
              alt="おすすめ霊園資料請求"
              width={600}
              height={336}
            />
          </a>
        </EventTracker>
      )}
      {shows.includes('hakajimai') && (
        <EventTracker label="side_墓じまい" action="click" clone>
          <a
            href={router.hakajimai()}
            target="_blank"
            css={styles.anchor(orientation)}
            rel="noopener noreferrer nofollow"
          >
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/52232/bn_hakajimai_sidebar.png"
              alt={`墓じまい相談：${lifedotCallCenterNumber}`}
              width={600}
              height={286}
            />
          </a>
        </EventTracker>
      )}
      {shows.includes('client') && (
        <EventTracker label="side_法人向けお問い合わせ" action="click" clone>
          <a
            href="https://forms.gle/CHoNLGaU8Xy5288G8"
            target="_blank"
            css={styles.anchor(orientation)}
            rel="noopener noreferrer nofollow"
          >
            <Image
              src="https://s3.lifedot.jp/uploads/ending/path/64918/bn_clientRequest_sidebar.png"
              alt="企業・法人向けお問い合わせフォーム"
              width={600}
              height={308}
            />
          </a>
        </EventTracker>
      )}
    </div>
  )
}
