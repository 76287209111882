import { ListPageRouterArg, listParams, listPath } from './listPage'
import { stringify } from 'querystring'
import {
  ShopListPageRouterArg,
  shopListParams,
  shopListPath
} from './shopListPage'

export const router = {
  prefectureIndex: (prefectureRoma: string): string =>
    `/ohaka/pref-${prefectureRoma}/`,
  list: (arg: ListPageRouterArg): string => {
    const query = stringify(listParams(arg))
    return `${listPath(arg)}${query ? `?${query}` : ''}`
  },
  cemetery: (cemeteryId: number | string): string =>
    `/ohaka/db/${cemeteryId.toString()}/`,
  review: (cemeteryId: number | string, count?: number): string =>
    Number(count) > 1
      ? `/ohaka/db/${cemeteryId.toString()}/review/`
      : `${router.cemetery(cemeteryId)}#a-Review`,
  price: (cemeteryId: number | string): string =>
    `/ohaka/db/${cemeteryId.toString()}/price/`,
  access: (cemeteryId: number | string): string => {
    return `${router.cemetery(cemeteryId)}#a-access`
  },
  shop: (shopId: number | string): string => `/shop/${shopId.toString()}/`,
  shopList: (arg: ShopListPageRouterArg): string => {
    const query = stringify(shopListParams(arg))
    return `${shopListPath(arg)}${query ? `?${query}` : ''}`
  },

  post: (slug: string, pageNumber?: number): string => {
    if ((pageNumber ?? 0) < 2) return slug === 'top' ? '/' : `/${slug}/`
    return `/${slug}/page/${pageNumber}/`
  },
  requestsNew: (args?: {
    id?: number | string | string[] | number[]
    tour?: boolean
    section_types?: string[]
    reservingDate?: string
  }): string => {
    const query = stringify(args)
    return `/ohaka/requests/new/${query ? `?${query}` : ''}`
  },
  customerDetailForm: (args: {
    id: number[]
    uuid: string
    withTour?: boolean
    code?: string
  }): string => {
    const query = stringify(args)
    return `/ohaka/requests/customerdetail/${query ? `?${query}` : ''}`
  },
  thanks: (args: {
    uuid: string
    id: number[]
    tour?: boolean
    withTour?: boolean
    addId?: number[]
    code?: string
  }): string => {
    const query = stringify(args)
    return `/ohaka/requests/complete/${query ? `?${query}` : ''}`
  },
  dialogueStep: (step: number): string => `/ohaka/dialogue/step${step}/`,
  dialogueResult: (): string => '/ohaka/dialogue/result/',
  hakajimai: (): string => 'https://ad.lifedot.jp/hakajimai',
  call: (): string => 'https://ad.lifedot.jp/call',
  lpGraestone: () => '/ohaka/lp_gravestone/',
  gravestoneRequestComplete: (args: { code: string }) => {
    const query = stringify(args)
    return `/ohaka/gravestone_requests/complete/${query ? `?${query}` : ''}`
  },
  hakajimaiRequestNew: () => '/ohaka/hakajimai_requests/new/',
  hakajimaiRequestComplete: (args: { code: string }): string => {
    const query = stringify(args)
    return `/ohaka/hakajimai_requests/complete/${query ? `?${query}` : ''}`
  },
  gravestoneRequestNew: () => '/ohaka/gravestone_requests/new/',
  areaPartnerRequestNew: () => '/ohaka/area_partner_requests/new',
  areaPartnerRequestComplete: (args: {
    code: string
    uuid: string
    conditionPrefectureIds: string[]
    conditionCityIds: string[]
    sectionTypes: string[]
  }) => {
    const query = stringify(args)
    return `/ohaka/area_partner_requests/complete/${query ? `?${query}` : ''}`
  }
}

export const urlify = (
  path: string,
  domain = process.env.NEXT_PUBLIC_LIFEDOT_DOMAIN
): string => new URL(path, domain).toString()

export const sanitize = (path: string): string => {
  const [sanitizedPath] = path.split('#')
  return sanitizedPath ?? path
}

export { listPathWithParamsForNextLink } from './listPage'
