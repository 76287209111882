import { css } from '@emotion/react'
import { FC, useEffect, useState } from 'react'
import { useBookmark } from '@lifedot/hooks/use-bookmark'
import { Badge } from '@lifedot/atoms/Badge'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import { StockListHeaderProps } from './StockListHeader'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import dynamic from 'next/dynamic'

const styles = {
  root: css({
    position: 'relative'
  }),
  button: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    padding: '0',
    color: palette.black
  }),
  close: css({
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    margin: 'auto',
    textDecoration: 'underline',
    color: palette.black,
    marginTop: 4
  })
}

export const StockListWithButton: FC = () => {
  const [bookmarks] = useBookmark()
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => setIsOpen((prev) => !prev)
  const handleClose = () => setIsOpen(false)
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  const StockListHeader = isOpen
    ? dynamic<StockListHeaderProps>(() =>
        import('./StockListHeader').then((mod) => mod.StockListHeader)
      )
    : (null as unknown as FC<StockListHeaderProps>)

  return (
    <div css={styles.root}>
      <button css={styles.button} onClick={handleClick}>
        <Badge content={mounted ? bookmarks.length : 0}>
          <StarBorderRoundedIcon />
        </Badge>
        <span css={typography.textXS}>検討リスト</span>
      </button>
      {isOpen && (
        <StockListHeader
          tools={
            <button
              css={[styles.close, typography.textS]}
              onClick={handleClose}
            >
              閉じる
              <ExpandLessIcon style={{ fontSize: 16 }} />
            </button>
          }
        />
      )}
    </div>
  )
}
