import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'

const styles = {
  side: css({
    width: 300,
    display: 'block',
    marginLeft: 40,
    flexShrink: 0,
    minWidth: 0,
    [mq('max', WindowWidth.TABLET)]: {
      width: '100%',
      margin: '52px 0 52px 0'
    }
  })
}

type SubColumnProps = {
  children: ReactNode
}

export const SubColumn: FC<SubColumnProps> = ({ children }) => (
  <aside css={styles.side}>{children}</aside>
)
