import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  root: (arg: CardContentProps) =>
    css([
      {
        padding: (arg.spacing ?? 0) * 4,
        borderTop: `${arg.borderTop ? 1 : 0}px solid ${palette.gray3}`,
        borderBottom: `${arg.borderBottom ? 1 : 0}px solid ${palette.gray3}`
      },
      arg.centering
        ? {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        : {}
    ])
}

export interface CardContentProps {
  centering?: boolean
  borderTop?: boolean
  borderBottom?: boolean
  spacing?: 0 | 1 | 2 | 3 | 4
  children?: ReactNode
}

export const CardContent: FC<CardContentProps> = (props) => {
  const { children, spacing = 0, ...rest } = props
  return <div css={styles.root({ ...rest, spacing })}>{children}</div>
}
