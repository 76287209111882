import { FC, ReactNode } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { PostListCard_posts$key } from '@/components/PostListCard/__generated__/PostListCard_posts.graphql'
import { Card, CardContent } from '@lifedot/atoms/Card'
import { typography } from '@lifedot/styles/typography'
import { List, ListItem } from '@lifedot/atoms/List'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { Frame } from '@lifedot/components/Frame'
import Image from 'next/image'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { replacementImageForPost } from '@lifedot/constants/images'

const styles = {
  title: css({
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '8px 0'
  }),
  anchor: css({
    textDecoration: 'none',
    fontWeight: 'bold',
    padding: '12px 0'
  }),
  img: css({
    width: 68
  }),
  caption: css({
    color: palette.gray6,
    textAlign: 'right'
  })
}

interface PostListCardProps {
  header?: ReactNode
  posts: PostListCard_posts$key
  footer?: ReactNode
}

export const PostListCard: FC<PostListCardProps> = ({
  posts,
  header,
  footer
}) => {
  const data = useFragment(fragment, posts)
  if (data.items.length < 0) return null

  return (
    <Card>
      <CardContent spacing={3}>
        {!!header && <p css={[typography.textL, styles.title]}>{header}</p>}
        <List border>
          {data.items.map(({ slug, h1, image }) => (
            <ListItem key={slug}>
              <Link href={router.post(slug)} passHref prefetch={false}>
                <a css={styles.anchor}>
                  <Frame
                    leftContent={
                      <figure css={styles.img}>
                        <Image
                          src={image?.url ?? replacementImageForPost.path}
                          alt={image?.alt ?? h1}
                          width={430}
                          height={242}
                        />
                      </figure>
                    }
                    rightContent={<p css={typography.textS}>{h1}</p>}
                  />
                </a>
              </Link>
            </ListItem>
          ))}
        </List>
        {!!footer && (
          <div css={[styles.caption, typography.textS]}>{footer}</div>
        )}
      </CardContent>
    </Card>
  )
}

const fragment = graphql`
  fragment PostListCard_posts on PostResult {
    items {
      h1
      slug
      image {
        url
        alt
      }
    }
  }
`
