import { FC } from 'react'
import { Card, CardContent } from '@lifedot/atoms/Card'
import { typography } from '@lifedot/styles/typography'
import { List, ListItem } from '@lifedot/atoms/List'
import { Accordion } from '@lifedot/atoms/Accordion'
import { css } from '@emotion/react'
import Link from 'next/link'
import { router } from '@lifedot/router'
import {
  prefectures,
  prefecturesByRegion
} from '@lifedot/constants/prefectures'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = {
  title: css({
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '8px 0'
  }),
  content: css({
    '> ul li': {
      display: 'block'
    }
  }),
  label: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    padding: 12
  }),
  anchor: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12
  })
}

export const AreaLinkAccordions: FC = () => {
  return (
    <Card>
      <CardContent spacing={3}>
        <p css={[typography.textL, styles.title]}>地域から霊園検索</p>
        <div css={styles.content}>
          <List border>
            {areas.map(({ label, prefectures }) => (
              <ListItem key={label}>
                <Accordion
                  title={
                    <div css={styles.label}>
                      {label}
                      <ExpandMoreIcon />
                    </div>
                  }
                >
                  <List border>
                    {prefectures.map(({ roma, name }) => (
                      <ListItem key={roma}>
                        <Link
                          href={router.prefectureIndex(roma)}
                          passHref
                          prefetch={false}
                        >
                          <a css={[typography.textS, styles.anchor]}>
                            {name}
                            <ChevronRightIcon fontSize="small" />
                          </a>
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Accordion>
              </ListItem>
            ))}
          </List>
        </div>
      </CardContent>
    </Card>
  )
}

const areas = [
  {
    label: '北海道・東北地方',
    prefectures: [
      ...prefecturesByRegion.hokkaido,
      ...prefecturesByRegion.tohoku
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '関東地方',
    prefectures: prefecturesByRegion.kanto.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '東海地方',
    prefectures: prefecturesByRegion.tokai.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '北陸・甲信越地方',
    prefectures: [
      ...prefecturesByRegion.hokuriku,
      ...prefecturesByRegion.koushinetsu
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '関西地方',
    prefectures: prefecturesByRegion.kansai.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  },
  {
    label: '中国・四国地方',
    prefectures: [
      ...prefecturesByRegion.chugoku,
      ...prefecturesByRegion.shikoku
    ].map((prefecture) => ({ roma: prefecture, name: prefectures[prefecture] }))
  },
  {
    label: '九州・沖縄地方',
    prefectures: prefecturesByRegion.kyushu.map((prefecture) => ({
      roma: prefecture,
      name: prefectures[prefecture]
    }))
  }
]
