import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { Header } from '../Header'
import { HeaderTel } from './HeaderTel'
import { mq } from '@lifedot/styles/mediaQuery'
import Image from 'next/image'
import Link from 'next/link'

const styles = {
  logo: css({
    width: 132,
    display: 'flex',
    alignItems: 'center',
    [mq('sp')]: {
      width: 88
    }
  }),
  tools: css({
    display: 'flex',
    alignItems: 'flex-end',
    '> :not(:last-of-type)': {
      marginRight: 16,
      [mq('sp')]: {
        marginRight: 12
      }
    }
  })
}

export interface HeaderWithToolsProps {
  additionalTool?: ReactNode
}

export const HeaderWithTools: FC<HeaderWithToolsProps> = ({
  additionalTool
}) => (
  <Header
    logo={
      <Link href="/" prefetch={false} passHref>
        <a css={styles.logo} itemProp="headline">
          <Image
            src="https://s3.lifedot.jp/uploads/ending/path/52461/logo.png"
            width={132}
            height={42}
            alt="お墓・霊園探しならライフドット"
            priority
          />
        </a>
      </Link>
    }
    tools={
      <div css={styles.tools}>
        {additionalTool}
        <HeaderTel />
      </div>
    }
  />
)
