import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'

const styles = {
  root: css({
    display: 'flex',
    justifyContent: 'space-between',
    [mq('max', WindowWidth.TABLET)]: {
      flexDirection: 'column'
    }
  })
}

type MultiColumnsProps = {
  children: ReactNode
}

export const MultiColumns: FC<MultiColumnsProps> = ({ children }) => (
  <div css={styles.root}>{children}</div>
)
