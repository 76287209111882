import {
  FC,
  useCallback,
  createContext,
  useContext,
  useMemo,
  PropsWithChildren
} from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'

type BookmarkItem = number

interface BookmarkHelpers {
  addBookmark: (arg: BookmarkItem | BookmarkItem[]) => void
  removeBookmark: (item: BookmarkItem | BookmarkItem[]) => void
  isBookmark: (item: BookmarkItem) => boolean
}

type BookmarkContextValue = [Array<BookmarkItem>, BookmarkHelpers]

const noop = (): void => {
  // no operation
}

const Context = createContext<BookmarkContextValue>([
  [],
  {
    addBookmark: noop,
    removeBookmark: noop,
    isBookmark: () => false
  }
])

interface BookmarkContextProviderProps {
  nameSpace: string
  max?: number
  onError?: () => void
  cookieOptions?: CookieSetOptions
}

export const BookmarkContextProvider: FC<
  PropsWithChildren<BookmarkContextProviderProps>
> = (props) => (
  <CookiesProvider>
    <Inner {...props} />
  </CookiesProvider>
)

const defaultCookieOptions = {
  path: '/',
  maxAge: 60 * 60 * 24 * 365, // 1YEAR
  secure: process.env.NEXT_PUBLIC_BUILD_ENV === 'production',
  httpOnly: false,
  sameSite: 'lax' as const
}

const Inner: FC<PropsWithChildren<BookmarkContextProviderProps>> = ({
  nameSpace,
  children,
  max = 20,
  onError = noop,
  cookieOptions = defaultCookieOptions
}) => {
  const [cookies, setCookie] = useCookies()
  const bookmarks = useMemo(
    () => (cookies[nameSpace] as Array<BookmarkItem>) ?? [],
    [cookies, nameSpace]
  )
  const addBookmark = useCallback(
    (item: BookmarkItem | BookmarkItem[]) => {
      const data = Array.from(
        new Set([...bookmarks, ...(Array.isArray(item) ? item : [item])])
      )
      if (data.length > max) {
        onError()
        return
      }
      setCookie(nameSpace, data, cookieOptions)
    },
    [bookmarks, nameSpace]
  )
  const removeBookmark = useCallback(
    (item: BookmarkItem | BookmarkItem[]) => {
      if (Array.isArray(item)) {
        setCookie(
          nameSpace,
          bookmarks.filter((bookmark) => !item.includes(bookmark)),
          cookieOptions
        )
      } else {
        setCookie(
          nameSpace,
          bookmarks.filter((bookmark) => item !== bookmark),
          cookieOptions
        )
      }
    },
    [bookmarks, nameSpace]
  )

  const isBookmark = useCallback(
    (item: BookmarkItem) => bookmarks.includes(item),
    [bookmarks]
  )

  return (
    <Context.Provider
      value={[bookmarks, { addBookmark, removeBookmark, isBookmark }]}
    >
      {children}
    </Context.Provider>
  )
}

export const useBookmark = (): BookmarkContextValue => useContext(Context)
